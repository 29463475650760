
<script>
import APP_CONST from '@/constants/AppConst';
import { Line } from 'vue-chartjs';

export default {
  extends: Line,
  name: 'LineChart',
  props: ['data', 'maxYCoordinate', 'isMobileWidth', 'yScaleLabel', 'isTooltipInPercent', 'hideAnnotation', 'isLabelOffSet', 'isCount', 'isQualityGraph', 'showLabelsWithInclination'],
  watch: {
    data(newData) {
      this.renderChartData();
    }
  },
  mounted() {
    this.renderChartData();
  },
  computed: {
    chartData() {
      const gridLineColor = [];
      gridLineColor.push(APP_CONST.STYLE.COLOR.NEUTRAL_500);
      const chartData = {
        labels: [],
        datasets: [
        ],
      };
      chartData.labels = this.data && this.data.labels
        ? this.data.labels
        : [];
      if (this.data && this.data.datasets) {
        for (let i = 0; i < this.data.datasets.length; i++) {
          chartData.datasets.push(this.data.datasets[i]);

        }
      }
      return chartData;
    },
    options() {
      const mobileWidth = this.isMobileWidth;
      const isQualityChart = this.isQualityGraph;
      const options = {
        layout: {
          padding: {
            bottom: 20,
          }
        },
        tooltips: {
          enabled: true,
          position: 'average',
          mode: 'single',
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10,
          displayColors: false,
          callbacks: {
            label: ((tooltipItems, data) => {
              if (tooltipItems.yLabel) {
                tooltipItems.yLabel = Math.round(tooltipItems.yLabel);
              }
              return !this.isTooltipInPercent
                ? this.isCount
                  ? data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].activeScholar + '/' + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].participatingScholar
                  : this.isQualityGraph
                    ? Number(tooltipItems.value).toFixed(1)
                    : tooltipItems.yLabel + ''
                : tooltipItems.yLabel + '%';
            }),
            title: function (tooltipItem, data) {
              return;
            }
          }
        },
        animation: {
          onComplete: function () {
            const scale = window.devicePixelRatio;
            const sourceCanvas = this.chart.chart.canvas;
            const copyWidth = this.chart.scales['y-axis-0'].width - 8;
            const copyHeight = this.chart.scales['y-axis-0'].height + this.chart.scales['y-axis-0'].top + 5;
            const targetCtx = document.getElementById('myChartAxis').getContext('2d');
            targetCtx.scale(scale, scale);
            targetCtx.canvas.width = copyWidth * scale;
            targetCtx.canvas.height = copyHeight * scale;
            targetCtx.canvas.style.width = `${copyWidth}px`;
            targetCtx.canvas.style.height = `${copyHeight}px`;
            targetCtx.drawImage(sourceCanvas, 0, 0, copyWidth * scale, copyHeight * scale, 0, 0, copyWidth * scale, copyHeight * scale);
            const sourceCtx = sourceCanvas.getContext('2d');
            sourceCtx.clearRect(0, 0, copyWidth, copyHeight);
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                zeroLineWidth: 1,
                zeroLineColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                drawTicks: false,
              },
              ticks: {
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: 12,
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                padding: 10,
                display: true,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                zeroLineWidth: 1,
                zeroLineColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
              },
              ticks: {
                stepSize: !this.isQualityGraph
                  ? this.maxYCoordinate
                    ? (this.maxYCoordinate / 10)
                    : 10
                  : 1,
                max: !this.isQualityGraph
                  ? this.maxYCoordinate
                    ? this.maxYCoordinate
                    : 100
                  : 4,
                min: 0,
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
                fontSize: 12,
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_700,
                fontWeight: 'normal',
              },
              scaleLabel: {
                display: true,
                labelString: this.yScaleLabel,
                fontSize: 16,
                fontStyle: 'bold',
                fontColor: APP_CONST.STYLE.COLOR.NEUTRAL_900,
                fontFamily: APP_CONST.STYLE.FONT_FAMILY.ARLY_FONT,
              },
            },
          ],
        },
        responsive: true,
        spanGaps: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          onClick: null,
          position: 'bottom',
          labels: {
            boxWidth: 0,
            fontColor: 'transparent',
            padding: '0'
          }
        },
        annotation: {
          drawTime: 'beforeDatasetsDraw',
          annotations: [{
            id: 'hline',
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: this.isQualityGraph
              ? 3
              : 80,
            borderColor: APP_CONST.STYLE.COLOR.NEUTRAL_500,
            borderWidth: 0,
            borderDash: [7, 7],
            borderDashOffset: 5,
          }]
        }
      };
      if (this.hideAnnotation) {
        options.annotation = null;
      }
      return options;
    },
  },
  methods: {
    renderChartData() {
      this.renderChart(this.chartData, this.options);
    }
  }
};
</script>