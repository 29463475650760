










































































import CompletionAttendanceAnalyticsComponent from '@/components/analyticsComponent/scholarAnalyticsComponent/scholarAttendanceAnalyticsComponent/completionAttendanceAnalyticsComponent/CompletionAttendanceAnalyticsComponent';
export default CompletionAttendanceAnalyticsComponent;
